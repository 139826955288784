export default {
  data: function () {
    return {
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          labels: {
            format: 'd',
            /*
            formatter: function (val, timestamp) {
              return moment(new Date(timestamp)).format("DD MMM YYYY")
            } */
          },
          axisTicks: {
            show: false,
            color: '#aaa',
          },
          axisBorder: {
            show: false,
            color: '#333',
          },
        },
        chart: {
          type: 'area',
          foreColor: '#fff',
          stacked: false,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        stroke: {
          curve: 'smooth',
        },
        tooltip: {
          theme: 'dark',
        },
        fill: {
          gradient: {
            enabled: false,
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        grid: {
          borderColor: '#666',
        },
        markers: {
          size: 0,
          style: 'full',
          // strokeWidth: 0,
          // strokeOpacity: 1
        },
        theme: {
          palette: 'palette1',
        },
      },
    }
  },
}
